import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  HeaderContainer,
  RelativeWrapperWithImage,
  MainSection,
  RelativeWrapper,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import TabNavigator from 'components/TabNavigator';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;

const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
  ${HeaderMedium} {
    padding-top: 100px;
    text-align: center;
  }
  .card {
   margin-top: 10px;
  }
  .header-title {
    margin-top: 40px;
    margin-bottom:10px;
  }
  h1.header-title {
    font-size:16px;
  }
  .details, li {
    margin-top: 10px;
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  .table th, .table td {
    padding: 0.5rem 1rem;
    font-size: 16px;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
  }
  .api-field-name {
    font-weight: 700;
  }
  .container-code {
    background: #f6f6f6;
    border-radius: 5px;
    padding: 10px 15px;
  }
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-family: CircularStd;
  }
  .get-api {
    margin-left: 15px;
  }
  @media 
  only screen and (max-width: 760px),
  (min-width: 472px) and (max-width: 1024px)  {

  table, thead, tbody, th, td, tr { 
  display: block; 
  }
`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const ProductsAPI = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Products API | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "ecommerce-meta-title-products-api" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "products-api-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "products-api-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "products-api-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "products-api-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Access Shipkoo products and inventory information. Get the product API now." />
        <meta property="og:title" content={intl.formatMessage({ id: "ecommerce-meta-title-products-api" })} />
        <meta property="og:description" content="Access Shipkoo products and inventory information. Get the product API now." />
        <meta name="twitter:title" content={intl.formatMessage({ id: "ecommerce-meta-title-products-api" })} />
        <meta name="twitter:description" content="Access Shipkoo products and inventory information. Get the product API now." />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={apiDocsLinks} activeTab={apiDocsLinks[1].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={apiDocsLinksChinese} activeTab={apiDocsLinksChinese[1].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={apiDocsLinksKorean} activeTab={apiDocsLinksKorean[1].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={apiDocsLinksJapanese} activeTab={apiDocsLinksJapanese[1].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / <Link to="/ecommerce-store-integration/">{intl.formatMessage({ id: "menu-platform-integrations" })}</Link> / {intl.formatMessage({ id: "product-api-title" })}</Breadcrumbs>
      <RelativeWrapperWithImage>
        <WidthWrapper whiteMobile>
          <Wrapper>
            <div className="card">
              <div className="card-body">
                <h1 className="header-title">{intl.formatMessage({ id: "product-api-title" })}</h1>
                  <p className="details">{intl.formatMessage({ id: "product-api-desc" })}</p>
                <hr />
                <ul>
                  <li>
                    <p>GET /api/product</p>
                    <p><small>Retrieves all the Products</small></p>
                  </li>
                  <li>
                    <p>GET /api/product/{"{id}"}</p>
                    <p><small>Retrieves all the Products</small></p>
                  </li>
                </ul>
                <hr />
                <table className="table">         
                  <tbody>
                    <tr>
                      <td className="api-field-name">id</td>
                      <td>
                        <p>The ID or unique identifier of the product</p>
                        <pre className="container-code">"id": 1</pre>
                      </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">sku</td>
                        <td>
                            <p>Product SKU</p>
                            <pre className="container-code">"sku": 02545255-4565588</pre>
                        </td>
                    </tr>
                    <tr>
                      <td className="api-field-name">name</td>
                      <td>
                        <p>Product name</p>
                        <pre className="container-code">"name": OPPO a5s</pre>
                      </td>
                    </tr>
                    <tr>
                      <td className="api-field-name">bar_code</td>
                      <td>
                        <p>Bar code</p>
                        <pre className="container-code">"bar_code": 524156465465</pre>
                      </td>
                    </tr>             
                    <tr>
                      <td className="api-field-name">status</td>
                      <td>
                        <p>Product status</p>
                                        <pre className="container-code">"status": Available</pre>
                      </td>
                    </tr>             
                    <tr>
                      <td className="api-field-name">physical_stock</td>
                      <td>
                        <p>Lists the how many stocks</p>
                        <pre className="container-code">"physical_stock": 200</pre>
                      </td>
                    </tr>             
                    <tr>
                      <td className="api-field-name">available_stock</td>
                      <td>
                        <p>Lists the how many stocks are available</p>
                        <pre className="container-code">"available_stocks": 150</pre>
                      </td>
                    </tr>                                             
                    <tr>
                      <td className="api-field-name">price</td>
                      <td>
                        <p>Product  price</p>
                        <pre className="container-code">"price": 1500</pre>
                      </td>
                    </tr>
                    <tr>
                      <td className="api-field-name">package_content</td>
                      <td>
                        <p>Lists the different contents of the product package</p>
                        <pre className="container-code">"package_content": Holder, Charger, Tempered glass</pre>
                      </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">weight</td>
                        <td>
                            <p>Product weight</p>
                            <pre className="container-code">"weight": 150</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">weight_unit</td>
                        <td>
                            <p>Box weight</p>
                            <pre className="container-code">"weight_unit": 300</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">lenght</td>
                        <td>
                            <p>Product lenght</p>
                            <pre className="container-code">"lenght": 150</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">width</td>
                        <td>
                            <p>Product width</p>
                            <pre className="container-code">"width": 5</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">height</td>
                        <td>
                            <p>Product height</p>
                            <pre className="container-code">"height": 350</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">size_unit</td>
                        <td>
                            <p>Unit size</p>
                            <pre className="container-code">"size_unit": 550</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">tariff_code</td>
                        <td>
                            <p>Tariff code</p>
                            <pre className="container-code">"tariff_code": 546544564698</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">packaging_type</td>
                        <td>
                            <p>Packaging pype</p>
                            <pre className="container-code">"packaging_type": Standard Box</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_fragile</td>
                        <td>
                            <p>Fragile product</p>
                            <pre className="container-code">"flag_fragile": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_dangerous</td>
                        <td>
                            <p>Dangerous product</p>
                            <pre className="container-code">"flag_dangerous": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_perishable</td>
                        <td>
                            <p>Perishable product</p>
                            <pre className="container-code">"flag_perishable": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_liquid</td>
                        <td>
                            <p>Liquid product</p>
                            <pre className="container-code">"flag_liquid": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_battery</td>
                        <td>
                            <p>Battery product</p>
                            <pre className="container-code">"flag_battery": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_regulated</td>
                        <td>
                            <p>Regulated product</p>
                            <pre className="container-code">"flag_regulated": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_media</td>
                        <td>
                            <p>Media product</p>
                            <pre className="container-code">"flag_media": ---</pre>
                        </td>
                    </tr>
                    <tr>
                        <td className="api-field-name">flag_adult</td>
                        <td>
                            <p>Adult product</p>
                            <pre className="container-code">"flag_adult": ---</pre>
                        </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <h4 className="header-title">{intl.formatMessage({ id: "product-api-sample-product-api-title" })}</h4>
                <pre className="container-code">
                  {'{'}<br />
                  &emsp;  "version": "1.0.0",<br />
                  &emsp;  "author_url": "https://www.shipkoo.com",<br />
                  &emsp;  "data": [<br />
                  &emsp;      {'{'}<br />
                  &emsp;          "id": "1",<br />
                  &emsp;          "name": "OPPO a5s",<br />
                  &emsp;          "sku": "02545255-4565588",<br />
                  &emsp;          "bar_code": "6545944889484456",<br />
                  &emsp;          "status": "Available",<br />
                  &emsp;          "physical_stock": "200",<br />
                  &emsp;          "available_stock": "250",<br />
                  &emsp;          "price": "1500",<br />
                  &emsp;          "package_content": "Holder, Charger, Tempered glass",<br />
                  &emsp;          "weight": "150",<br />
                  &emsp;          "weight_unit": "",<br />
                  &emsp;          "length": "150",<br />
                  &emsp;          "width": "5",<br />
                  &emsp;          "height": "300",<br />
                  &emsp;          "size_unit": "200",<br />
                  &emsp;          "tariff_code": "65465648",<br />
                  &emsp;          "packaging_type": "Standard Box",<br />
                  &emsp;          "flag_fragile": "---",<br />
                  &emsp;          "flag_dangerous": "---",<br />
                  &emsp;          "flag_perishable": "---",<br />
                  &emsp;          "flag_liquid": "---",<br />
                  &emsp;          "flag_battery": "---",<br />
                  &emsp;          "flag_regulated": "---",<br />
                  &emsp;          "flag_media": "---",<br />
                  &emsp;          "flag_adult": "---",<br />
                  &emsp;      }<br />    
                  &emsp;  ],<br />
                  &emsp;  "links": {'{'}<br />
                  &emsp;      "first": "https://shipkoo.com/api/product?page=1",<br />
                  &emsp;      "last": "https://shipkoo.com/api/product?page=54",<br />
                  &emsp;      "prev": null,<br />
                  &emsp;      "next": "https://shipkoo.com/api/product?page=2"<br />
                  &emsp;  },<br />
                  &emsp;  "meta": {'{'}<br />
                  &emsp;      "current_page": 1,<br />
                  &emsp;      "from": 1,<br />
                  &emsp;      "last_page": 54,<br />
                  &emsp;      "path": "https://shipkoo.com/api/product",<br />
                  &emsp;      "per_page": 15,<br />
                  &emsp;      "to": 15,<br />
                  &emsp;      "total": 804<br />
                  &emsp;  }<br />
                  &emsp;}<br />
                </pre>
                <h4 className="header-title">{intl.formatMessage({ id: "product-api-retrieving-product-title" })}</h4>
                <h4 className="header-title">{intl.formatMessage({ id: "product-api-retrieving-product-header" })}</h4>
                <ul>
                  <li>Authorization: Bearer {'{Your-API-Token}'}</li>
                  <li>Accept: application/json</li>
                </ul>
                <small className="get-api">GET /api/product</small>
                <hr />
                <h4 className="header-title">{intl.formatMessage({ id: "product-api-retrieving-specific-title" })}</h4>
                <h4 className="header-title">{intl.formatMessage({ id: "product-api-retrieving-specific-header" })}</h4>
                <ul>
                  <li>Authorization: Bearer {'{Your-API-Token}'}</li>
                  <li>Accept: application/json</li>
                </ul>
                <small className="get-api">GET /api/product/{'{id}'}</small>
              </div>
            </div>
          </Wrapper>
        </WidthWrapper>
      </RelativeWrapperWithImage>
    </Layout>
  );
};

export default ProductsAPI;
